/* eslint-disable no-dupe-keys */
import { createRouter, createWebHashHistory } from "vue-router";
import Login from "../views/login.vue";

const routes = [
  {
    path: "/",
    name: "login",
    component: Login,
    meta: {
      title: "登录",
    },
  },
  {
    path: "/auth",
    name: "auth",
    component: () => import("../views/auth.vue"),
    meta: {
      title: "绑定",
    },
  },
  {
    path: "/index",
    name: "index",
    redirect: "index/be",
    component: () => import("../views/index.vue"),
    children: [
      { path: "be", component: () => import("../components/beCleaned.vue") },
      {
        path: "have",
        component: () => import("../components/haveCleaned.vue"),
      },
    ],
    meta: {
      title: "打扫",
    },
  },
  {
    path: "/bluetooth",
    name: "bluetooth",
    component: () => import("../views/bluetooth.vue"),
    meta: {
      title: "蓝牙门锁",
    },
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to, next) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next;
});

export default router;
